import React from "react"

import Layout from "../../components/layout/layout-st"
import Seo from "../../components/seo"

import Helmet from "react-helmet"

const Tour = () => (
  <Layout>
    <Seo title="Welcome - SpecyalT" bodyClass='st' />
    <div className="column column--100">
    <Helmet>
      <script src="https://widget.bandsintown.com/main.min.js"></script>
    </Helmet>

    <div className="bit-widget-initializer" 
    data-artist-name="Specyal T" 
    data-display-local-dates="true" 
    data-display-past-dates="false" 
    data-auto-style="false" 
    data-text-color="#000000" 
    data-link-color="#662d91" 
    data-background-color="rgba(0,0,0,0)" 
    data-display-limit="15" 
    data-display-start-time="false" 
    data-link-text-color="#FFFFFF" 
    data-display-lineup="false" 
    data-display-play-my-city="true" 
    data-separator-color="rgba(255, 255, 255, 0.5)">Tour</div>

    </div>
  </Layout>
)

export default Tour



